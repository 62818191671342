export type AskResponse = {
    answer: string;
    citations: Citation[];
    error?: string;
    message_id?: string;
    feedback?: Feedback;
};

export type Citation = {
    origin: string;
    part_index?: number;
    content: string;
    id: string;
    title: string | null;
    filepath: string | null;
    url: string | null;
    metadata: string | null;
    chunk_id: string | null;
    reindex_id: string | null;
}

export type ToolMessageContent = {
    citations: Citation[];
    intent: string;
}

export type WebBrowserToolMessageContent = {
    citations: string[];
}

export type ChatMessage = {
    id: string;
    role: string;
    content: string;
    webBrowserContent?: string;
    citations?: Citation[];
    webBrowserCitations?: Citation[];
    end_turn?: boolean;
    date: string;
    feedback?: Feedback;
    context?: string;
};

export type Conversation = {
    id: string;
    title: string;
    messages: ChatMessage[];
    date: string;
    knowledgeDomain?: KnowledgeDomain;
}

export enum ChatCompletionType {
    ChatCompletion = "chat.completion",
    ChatCompletionChunk = "chat.completion.chunk"
}

export type ChatResponseChoice = {
    messages: ChatMessage[];
}

export type ChatResponse = {
    id: string;
    model: string;
    created: number;
    object: ChatCompletionType;
    choices: ChatResponseChoice[];
    history_metadata: {
        conversation_id: string;
        title: string;
        date: string;
    }
    followup_questions: string[];
    error?: any;
}

export type ConversationRequest = {
    conversationId?: string;
    knowledgeDomain: KnowledgeDomain;
    messages: ChatMessage[];
};

export type UserInfo = {
    access_token: string;
    expires_on: string;
    id_token: string;
    provider_name: string;
    user_claims: any[];
    user_id: string;
};

export enum CosmosDBStatus {
    NotConfigured = "CosmosDB is not configured",
    NotWorking = "CosmosDB is not working",
    InvalidCredentials = "CosmosDB has invalid credentials",
    InvalidDatabase = "Invalid CosmosDB database name",
    InvalidContainer = "Invalid CosmosDB container name",
    Working = "CosmosDB is configured and working",
}

export type CosmosDBHealth = {
    cosmosDB: boolean,
    status: string
}

export enum ChatHistoryLoadingState {
    Loading = "loading",
    Success = "success",
    Fail = "fail",
    NotStarted = "notStarted"
}

export type ErrorMessage = {
    title: string,
    subtitle: string
}

export type UI = {
    title: string;
    chat_title: string;
    chat_description: string;
    logo?: string;
    chat_logo?: string;
    show_share_button?: boolean;
    chat_assistant_logo?: string;
}

export type FrontendSettings = {
    auth_enabled?: string | null;
    feedback_enabled?: string | null;
    ui?: UI;
}

export enum Feedback {
    Neutral = "neutral",
    Positive = "positive",
    Negative = "negative",
    MissingCitation = "missing_citation",
    WrongCitation = "wrong_citation",
    OutOfScope = "out_of_scope",
    InaccurateOrIrrelevant = "inaccurate_or_irrelevant",
    OtherUnhelpful = "other_unhelpful",
    HateSpeech = "hate_speech",
    Violent = "violent",
    Sexual = "sexual",
    Manipulative = "manipulative",
    OtherHarmful = "other_harmlful"
}

export type KnowledgeDomain = {
    id: string;
    label: string; // Title
    description: string; // Description
    disabled: boolean;
    iconUrl?: string;  // Icon URL
    indexName: string; // Index name
    containerName: string; // Blob container name
};

export type Topic = {
    id: number;
    title: string;
    description: string;
    userPrompt: string;
};

export type TenantSettings = {
    id: number;
    name: string;
    domain: string;
    active: boolean;
    header: {
        title: string;
        subtitle: string;
        description: string;
        logoPath: string;
    },
    footer: [{
        id: number,
        title: string;
        url: string;
    }],
    topics: Topic[],
    sharepointConfigs: {
        parentDirectory: string;
        directory: string;
        informationLink: string;
    },
    visibility: {
        header?: boolean,
        footer?: boolean,
        toggle?: boolean,
        info?: boolean,
        prompts?: boolean,
        fileUploader?: boolean,
        history?: boolean,
        knowledgeDomains?: boolean
    },
    tooltip: {
        info: string;
        samplePrompt: string;
    },
    knowledgeDomains: KnowledgeDomain[],
};

export enum UploadFileStatus {
    Uploading = "Uploading",
    Indexing = "Indexing",
    Deleting = "Deleting",
    Completed = "Completed"
}

export type UploadedFile = {
    name: string;
    status: UploadFileStatus;
    file?: File;
    splittedFilename?: string[];
};

export enum UploadedFilesLoadingState {
    Loading = "loading",
    Success = "success",
    Fail = "fail",
    NotStarted = "notStarted"
};

export enum ExportFileFormat {
    PDF = "pdf",
    DOCX = "docx",
};