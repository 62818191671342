import styles from "./NewSession.module.css";

import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AppStateContext } from "../../state/AppProvider";

import { useChatStore } from "../../store/Chat.store";

import QuestionInput from "../../components/QuestionInput/QuestionInput";

const NewSession: React.FunctionComponent = () => {
    const appStateContext = useContext(AppStateContext);
    const preMadePrompts = appStateContext?.state.tenantSettings?.topics ?? [];
    const navigate = useNavigate();

    const setPromptAsTopic = (prompt: string) => {
        useChatStore.setState({ userPrompt: prompt });
    };

    const triggerInitialConversationMessage = (question: string) => {
        if (!question || question.length < 1) {
            return;
        }
        appStateContext?.dispatch({ type: "TRIGGERING_NEW_CONVERSATION_PROMPT", payload: question });
        navigate("/chat");
    };

    return (
        <div className={styles["new-session__container"]}>
            <div className={styles["new-session__block__top__container"]}>
                <div className={styles["new-session__block__top"]}>
                    <div className={styles["new-session__block__top__heading"]}>How can I help you today?</div>
                    <div className={styles["new-session__block__top__knowledge-domain__information"]}>
                        You are using the&nbsp;
                        <span className={styles["new-session__block__top__knowledge-domain__name"]}>{appStateContext?.state.currentKnowledgeDomain?.label}</span>
                        &nbsp;knowledge domain.&nbsp;
                        {
                            !appStateContext?.state.isSingleKnowledgeDomain
                            && <span className={styles["new-session__block__top__knowledge-domain__button"]} onClick={() => { appStateContext?.dispatch({ type: "SET_OPEN_KNOWLEDGE_DOMAIN_MODAL", payload: true }); }}>Change</span>
                        }
                    </div>
                    <div className={styles["new-session__block__top__question-input__container"]}>
                        <QuestionInput
                            clearOnSend
                            placeholder="Start typing anything you need"
                            disabled={false}
                            onSend={triggerInitialConversationMessage}
                        />
                    </div>
                </div>
            </div>
            <div className={styles["new-session__block__bottom__container"]}>
                <div className={styles["new-session__block__bottom"]}>
                    {
                        preMadePrompts && preMadePrompts.length > 0
                        && <div className={styles["new-session__block-pre-made-prompts"]}>
                            <div className={styles["new-session__block-pre-made-prompts__heading"]}>Or select a pre-made prompt:</div>
                            <div className={styles["new-session__block-pre-made-prompts__list"]}>
                                {
                                    preMadePrompts.map(
                                        prompt => {
                                            return (
                                                <div className={styles["new-session__block-pre-made-prompts__list__item"]} onClick={() => setPromptAsTopic(prompt.userPrompt)} title={prompt.userPrompt}>
                                                    <div className={styles["new-session__block-pre-made-prompts__list__item__title"]}>{prompt.title}</div>
                                                    {/* <div className={styles["new-session__block-pre-made-prompts__list__item__description"]}>{prompt.userPrompt}</div> */}
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </div>
                            <div className={styles["new-session__block-pre-made-prompts__block_end"]}></div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default NewSession;