import styles from "./TenantPanel.module.css";

interface TenantPanelProps {
    logoUrl?: string;
    name: string;
    madisonAiUrl: string;
};

const TenantPanel: React.FunctionComponent<TenantPanelProps> = ({ logoUrl, name, madisonAiUrl }) => {
    return (
        <div className={styles["tenant-panel__container"]}>
            <div className={styles["tenant-panel__logo__container"]}>
                {
                    logoUrl?.length ?? 0 > 0 ?
                        <a href="/">
                            <img className={styles["tenant-panel__logo"]} src={logoUrl} />
                        </a>
                        : <a href="/">
                            <div className={styles["tenant-panel__logo__placeholder"]}></div>
                        </a>
                }
            </div>
            <div className={styles["tenant-panel__information"]}>
                <div className={styles["tenant-panel__information__name"]}>{name}</div>
                <div className={styles["tenant-panel__information__powered-by"]}>powered by <a className={`${styles["tenant-panel__information__madisonai-url"]} ${styles["tenant-panel__information__powered-by"]}`} href={madisonAiUrl}>Madison AI</a></div>
            </div>
        </div>
    );
};

export default TenantPanel;