// LoadingSequence.tsx
import React, { useState, useEffect, useRef } from 'react';
import styles from './LoadingSequence.module.css';

const LoadingSequence: React.FC = () => {
    const [currentPhase, setCurrentPhase] = useState<string>('Searching...');
    const [documentNumber, setDocumentNumber] = useState<number>(1);
    const [processingNumber, setProcessingNumber] = useState<number>(1);
    const [isCountingDocuments, setIsCountingDocuments] = useState<boolean>(false);
    const [isCountingProcessing, setIsCountingProcessing] = useState<boolean>(false);
    const [dotCount, setDotCount] = useState<number>(1); // For cycling the ellipses

    const totalDocumentsRef = useRef<number>(
        Math.floor(Math.random() * (208 - 142 + 1)) + 142
    );
    const currentTimeRef = useRef<number>(0);

    // Cycle dotCount (1 to 3) every 500ms for animated ellipses
    useEffect(() => {
        const dotInterval = setInterval(() => {
            setDotCount(prev => (prev % 3) + 1);
        }, 500);
        return () => clearInterval(dotInterval);
    }, []);

    useEffect(() => {
        let docIntervalId: number | null = null;
        if (isCountingDocuments) {
            docIntervalId = window.setInterval(() => {
                setDocumentNumber(prev => {
                    if (prev < totalDocumentsRef.current) {
                        return prev + 1;
                    } else {
                        if (docIntervalId) clearInterval(docIntervalId);
                        return totalDocumentsRef.current;
                    }
                });
            }, 3);
        }

        return () => {
            if (docIntervalId) clearInterval(docIntervalId);
        };
    }, [isCountingDocuments]);

    useEffect(() => {
        let processIntervalId: number | null = null;
        if (isCountingProcessing) {
            processIntervalId = window.setInterval(() => {
                setProcessingNumber(prev => {
                    if (prev < totalDocumentsRef.current) {
                        return prev + 1;
                    } else {
                        if (processIntervalId) clearInterval(processIntervalId);
                        return totalDocumentsRef.current;
                    }
                });
            }, 3);
        }

        return () => {
            if (processIntervalId) clearInterval(processIntervalId);
        };
    }, [isCountingProcessing]);

    useEffect(() => {
        let mainIntervalId: number;

        const updateSequence = () => {
            const t = currentTimeRef.current;

            // Adjusted Timeline:
            // 0-4500 ms: Searching...
            // 4500-7000 ms: Retrieving documents...
            // 7000-34000 ms: Retrieving document 1-150 (27s)
            // 34000-36500 ms: Processing documents...
            // 36500-67500 ms: Processing document 1-150 (31s)
            // 67500-78500 ms: Analyzing... (11s instead of 9s)
            // 78500-85500 ms: Synthesizing data... (8s instead of 6s)
            // >85500 ms: Generating results...

            if (t < 4500) {
                setCurrentPhase('Searching...');
                setIsCountingDocuments(false);
            } else if (t < 7000) {
                setCurrentPhase('Retrieving documents...');
                setIsCountingDocuments(false);
            } else if (t < 34000) {
                // Retrieving counting phase
                if (t >= 7000 && t < 7100) {
                    setDocumentNumber(1);
                }
                setCurrentPhase(`Retrieving document ${documentNumber}`);
                setIsCountingDocuments(true);
            } else if (t < 36500) {
                setCurrentPhase('Processing documents...');
                setIsCountingDocuments(false);
            } else if (t < 67500) {
                // Processing counting phase
                if (t >= 36500 && t < 36600) {
                    setProcessingNumber(1);
                }
                setCurrentPhase(`Processing document ${processingNumber}`);
                setIsCountingProcessing(true);
                setIsCountingDocuments(false);
            } else if (t < 78500) {
                setCurrentPhase('Analyzing...');
                setIsCountingDocuments(false);
            } else if (t < 85500) {
                setCurrentPhase('Synthesizing data...');
                setIsCountingDocuments(false);
            } else {
                setCurrentPhase('Generating results...');
                setIsCountingDocuments(false);
            }

            currentTimeRef.current += 150;
            mainIntervalId = window.setTimeout(updateSequence, 25);
        };

        updateSequence();

        return () => {
            clearTimeout(mainIntervalId);
        };
    }, [documentNumber, processingNumber]);

    let displayedPhase = currentPhase;
    if (displayedPhase.includes('...')) {
        const dots = '.'.repeat(dotCount);
        displayedPhase = displayedPhase.replace('...', dots);
    }

    return (
        <div className={styles.loadingContainer}>
            <div className={styles.loadingText}>
                {displayedPhase}
            </div>
        </div>
    );
};

export default LoadingSequence;
