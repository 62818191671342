import styles from "./Layout.module.css";

import { useContext, useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { CosmosDBStatus, KnowledgeDomain } from "../../api";
import { useChatStore } from "../../store/Chat.store";

import { AppStateContext } from "../../state/AppProvider";

import { RIGHT_PANE_TAB_SOURCES_NAME, RIGHT_PANE_TAB_CITATIONS_NAME, RIGHT_PANE_TAB_EXPORT_NAME } from "../../constants/rightPane";

import { createTheme, DefaultButton, IButtonStyles, ISeparatorStyles, Pivot, PivotItem, Separator, ThemeProvider, Toggle } from "@fluentui/react";
import IconTooltipInfo from "../../components/common/IconTooltipInfo";
import { MenuItemIcon } from "../../components/common/MenuItemIcon";
import { SettingIcon } from "../../components/common/SettingIcon";

import TenantPanel from "../../components/Tenant/TenantPanel";
import KnowledgeDomainButton from "../../components/KnowledgeDomain/KnowledgeDomainButton";
import KnowledgeDomainModal from "../../components/KnowledgeDomain/KnowledgeDomainModal";
import ChatHistoryPanel from "../../components/ChatHistory/ChatHistoryPanel";
import ProfilePanel from "../../components/Profile/ProfilePanel";
import FileUpload from "../../components/FileUpload/FileUpload";
import Citation from "../../components/Citation/Citation";
import ChatExport from "../../components/ChatExport/ChatExport";

const IconNewSession: React.FunctionComponent<{ className?: string | undefined; }> = ({ className }) => {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.16667 16.6667C9.16667 16.8877 9.25446 17.0996 9.41074 17.2559C9.56702 17.4122 9.77899 17.5 10 17.5C10.221 17.5 10.433 17.4122 10.5893 17.2559C10.7455 17.0996 10.8333 16.8877 10.8333 16.6667V10.8333H16.6667C16.8877 10.8333 17.0996 10.7455 17.2559 10.5893C17.4122 10.433 17.5 10.221 17.5 10C17.5 9.77899 17.4122 9.56702 17.2559 9.41074C17.0996 9.25446 16.8877 9.16667 16.6667 9.16667H10.8333V3.33333C10.8333 3.11232 10.7455 2.90036 10.5893 2.74408C10.433 2.5878 10.221 2.5 10 2.5C9.77899 2.5 9.56702 2.5878 9.41074 2.74408C9.25446 2.90036 9.16667 3.11232 9.16667 3.33333V9.16667H3.33333C3.11232 9.16667 2.90036 9.25446 2.74408 9.41074C2.5878 9.56702 2.5 9.77899 2.5 10C2.5 10.221 2.5878 10.433 2.74408 10.5893C2.90036 10.7455 3.11232 10.8333 3.33333 10.8333H9.16667V16.6667Z" fill="white" />
        </svg>
    );
};

const IconOpenRightPane: React.FunctionComponent = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 27C16.4491 27.0376 14.9465 26.4587 13.8218 25.3902C12.697 24.3217 12.0419 22.8508 12 21.3V11.13C12.0288 10.0089 12.5006 8.94489 13.3122 8.17085C14.1237 7.39682 15.2088 6.97582 16.33 6.99999C17.4529 6.97313 18.5406 7.39294 19.3543 8.16726C20.168 8.94158 20.6412 10.0071 20.67 11.13V21.31C20.6284 21.9886 20.3295 22.6257 19.8343 23.0915C19.3391 23.5573 18.6849 23.8167 18.005 23.8167C17.3251 23.8167 16.6709 23.5573 16.1757 23.0915C15.6805 22.6257 15.3816 21.9886 15.34 21.31V11.92C15.34 11.6548 15.4454 11.4004 15.6329 11.2129C15.8204 11.0253 16.0748 10.92 16.34 10.92C16.6052 10.92 16.8596 11.0253 17.0471 11.2129C17.2346 11.4004 17.34 11.6548 17.34 11.92V21.31C17.3599 21.4723 17.4386 21.6217 17.5611 21.7301C17.6836 21.8385 17.8415 21.8983 18.005 21.8983C18.1685 21.8983 18.3264 21.8385 18.4489 21.7301C18.5714 21.6217 18.6501 21.4723 18.67 21.31V11.13C18.6389 10.5384 18.3758 9.98294 17.9377 9.58417C17.4996 9.18539 16.9219 8.97548 16.33 8.99999C15.7398 8.97817 15.1647 9.1893 14.7288 9.58779C14.2928 9.98629 14.0311 10.5402 14 11.13V21.3C14.0416 22.3204 14.486 23.2828 15.2357 23.9763C15.9854 24.6698 16.9794 25.0379 18 25C19.0206 25.0379 20.0146 24.6698 20.7643 23.9763C21.514 23.2828 21.9584 22.3204 22 21.3V11.13C22 10.8648 22.1054 10.6104 22.2929 10.4229C22.4804 10.2353 22.7348 10.13 23 10.13C23.2652 10.13 23.5196 10.2353 23.7071 10.4229C23.8946 10.6104 24 10.8648 24 11.13V21.3C23.9581 22.8508 23.303 24.3217 22.1782 25.3902C21.0535 26.4587 19.5509 27.0376 18 27Z" fill="#495158" />
        </svg>
    );
};

const IconCloseRightPane: React.FunctionComponent = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.8" fill-rule="evenodd" clip-rule="evenodd" d="M10.0048 11.1557L5.36538 15.7955C5.21071 15.9449 5.00354 16.0275 4.78851 16.0257C4.57348 16.0238 4.36778 15.9375 4.21573 15.7855C4.06367 15.6334 3.97742 15.4277 3.97555 15.2126C3.97369 14.9976 4.05635 14.7904 4.20574 14.6357L8.84514 9.996L4.20574 5.35627C4.05635 5.20158 3.97369 4.9944 3.97555 4.77936C3.97742 4.56431 4.06367 4.3586 4.21573 4.20653C4.36778 4.05447 4.57348 3.96821 4.78851 3.96634C5.00354 3.96447 5.21071 4.04714 5.36538 4.19654L10.0048 8.83627L14.6442 4.19654C14.7996 4.05083 15.0055 3.97129 15.2185 3.97475C15.4315 3.97821 15.6347 4.0644 15.7853 4.21507C15.9358 4.36575 16.0219 4.56909 16.0252 4.78208C16.0285 4.99507 15.9488 5.20099 15.803 5.35627L11.1644 9.996L15.8038 14.6357C15.8822 14.7114 15.9446 14.8019 15.9876 14.902C16.0306 15.002 16.0532 15.1096 16.0542 15.2185C16.0551 15.3274 16.0344 15.4354 15.9931 15.5362C15.9519 15.637 15.891 15.7286 15.814 15.8056C15.737 15.8826 15.6454 15.9435 15.5446 15.9848C15.4438 16.026 15.3359 16.0468 15.227 16.0458C15.1181 16.0449 15.0104 16.0222 14.9104 15.9793C14.8103 15.9363 14.7198 15.8738 14.6442 15.7955L10.0048 11.1557Z" fill="#1C252E" />
        </svg>
    );
};

const Layout = () => {
    const appStateContext = useContext(AppStateContext);
    const tenantSettings = appStateContext?.state.tenantSettings;
    const navigate = useNavigate();
    const leftPane = useRef<HTMLDivElement>(null);
    const rightPane = useRef<HTMLDivElement>(null);

    const toggleLeftPane = () => {
        appStateContext?.dispatch({ type: "TOGGLE_LEFT_PANE", payload: !appStateContext?.state.expandedLeftPane });
    }

    const toggleRightPane = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        appStateContext?.dispatch({ type: "TOGGLE_RIGHT_PANE", payload: !appStateContext?.state.expandedRightPane });
    };

    const user = appStateContext?.state.user;

    const clearSession = (knowledgeDomain: KnowledgeDomain) => {
        useChatStore.setState({ userPrompt: "" });
        appStateContext?.dispatch({ type: "UPDATE_CURRENT_CHAT", payload: null });
        appStateContext?.dispatch({ type: "SET_FOLLOW_UP_QUESTIONS", payload: [] });
        appStateContext?.dispatch({ type: "SET_ACTIVE_RIGHT_PANE_TAB", payload: RIGHT_PANE_TAB_SOURCES_NAME });
        appStateContext?.dispatch({ type: "TOGGLE_RIGHT_PANE", payload: false });
        appStateContext?.dispatch({ type: "SET_KNOWLEDGE_DOMAIN", payload: knowledgeDomain });
        navigate("/");
    };

    const openKnowledgeDomainModal = () => {
        appStateContext?.dispatch({ type: "SET_OPEN_KNOWLEDGE_DOMAIN_MODAL", payload: true });
    };
    const setKnowledgeDomain = (knowledgeDomain: KnowledgeDomain) => {
        appStateContext?.dispatch({ type: "SET_OPEN_KNOWLEDGE_DOMAIN_MODAL", payload: false });
        clearSession(knowledgeDomain);
    }

    const startNewSession = () => {
        clearSession(appStateContext?.state.initialKnowledgeDomain!);
    };

    const handleRightPaneTabClick = (tabName: string) => {
        if (tabName && tabName.length > 0) {
            appStateContext?.dispatch({ type: "SET_ACTIVE_RIGHT_PANE_TAB", payload: tabName });
        }
    };

    const handleClickOutsideLeftPane = (event: MouseEvent) => {
        if (leftPane.current && !leftPane.current.contains(event.target as Node) &&
            !((event.target as HTMLElement).classList.contains(styles["top-pane__home"]) || (event.target as HTMLElement).closest(`.${styles["top-pane__home"]}`))
        ) {
            appStateContext?.dispatch({ type: "TOGGLE_LEFT_PANE", payload: false });
        }
    }

    const setIsWebBrowsingEnabled = (checked: boolean) => {
        appStateContext?.dispatch({ type: "SET_WEBBROWSING_ENABLED", payload: checked });
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideLeftPane);

        return () => {
            document.removeEventListener('click', handleClickOutsideLeftPane);
        };
    }, []);

    const customWebBrowingToggleTheme = createTheme({
        semanticColors: {
            inputBackgroundChecked: '#8e33ff',
            inputBackgroundCheckedHovered: '#8e33ff'
        },
    });

    const supportButtonStyles: Partial<IButtonStyles> = {
        root: { height: "36px", minWidth: '64px', borderRadius: "8px", border: "1px solid rgba(145, 158, 171, 0.32)", backgroundColor: "#ffffff", padding: '0px 12px' },
        label: { fontWeight: 700, fontSize: "14px", lineHeight: "24px", color: "#1c252e", margin: '0px 0px' }
    };

    const leftPaneBlockSeparatorStyles: Partial<ISeparatorStyles> = {
        root: { padding: "0px" }
    };

    return (
        <div className={styles["layout__container"]}>
            <div className={styles["top-pane"]}>
                <div className={styles["top-pane__block__tenant"]}>
                    <TenantPanel logoUrl={tenantSettings?.header.logoPath} name={tenantSettings?.name ?? "MadisonAI"} madisonAiUrl={"https://madisonai.com/"} />
                </div>
                <div className={styles["top-pane__block__end"]}>
                    <DefaultButton
                        text="Need support?"
                        styles={supportButtonStyles}
                        onClick={() => { window.open("https://madisonai.com/contact/", "_blank"); }}
                    />
                    <ProfilePanel user={user} />
                </div>
            </div>
            <div className={styles["layout"]}>
                <div className={styles["top-pane--mobile"]}>
                    <div className={styles["top-pane__home"]} onClick={() => toggleLeftPane()}><MenuItemIcon /></div>
                    <div className={styles["top-pane__title"]}>Meeting Planning</div>
                    <div className={styles["top-pane__settings"]} onClick={(event) => toggleRightPane(event)}><SettingIcon /></div>
                </div>
                <div ref={leftPane} className={`${styles["left-pane"]} ${appStateContext?.state.expandedLeftPane ? styles['left-pane--expanded'] : ''}`}>
                    <div className={styles["left-pane__block__tenant"]}>
                        <TenantPanel logoUrl={tenantSettings?.header.logoPath} name={tenantSettings?.name ?? "MadisonAI"} madisonAiUrl={"https://madisonai.com/"} />
                        <Separator styles={leftPaneBlockSeparatorStyles} />
                    </div>
                    <div className={styles["left-pane__block__knowledge-domain"]}>
                        <div className={styles["left-pane__heading__text"]}>KNOWLEDGE DOMAIN</div>
                        <KnowledgeDomainButton openModal={openKnowledgeDomainModal} isDocked={true} />
                        <div className={styles["left-pane__button__new-session"]} onClick={startNewSession}><IconNewSession className={styles["left-pane__button__new-session__icon-add"]} />New session</div>
                    </div>
                    <Separator styles={leftPaneBlockSeparatorStyles} />
                    <div className={styles["left-pane__block__session-history"]}>
                        {
                            appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured &&
                            tenantSettings?.visibility.history &&
                            (appStateContext?.state.chatHistory?.length ?? 0) > 0 &&
                            <>
                                <div className={styles["left-pane__heading__text"]}>SESSION HISTORY</div>
                                <ChatHistoryPanel />
                            </>
                        }
                    </div>
                    <div className={styles["left-pane__block__profile"]}>
                        <Separator styles={leftPaneBlockSeparatorStyles} />
                        <ProfilePanel user={user} />
                    </div>
                    <div className={styles["left-pane__block_end"]}></div>
                </div>
                <div className={styles["center-pane"]}>
                    <Outlet />
                </div>
                <div ref={rightPane} className={`${styles["right-pane"]} ${appStateContext?.state.expandedRightPane ? styles["right-pane--expanded"] : ''}`}>
                    {
                        !appStateContext?.state.expandedRightPane
                        && <div className={styles["right-pane__toggle-button"]} onClick={(event) => toggleRightPane(event)}>
                            <IconOpenRightPane />
                        </div>
                    }
                    <div className={styles["right-pane__header"]}>
                        <Pivot
                            aria-label="right pane tabs"
                            styles={{
                                text: { fontWeight: 500, fontSize: "14px", lineHeight: "22px" },
                                link: { color: "#637381" },
                                linkIsSelected: { color: '#1c252e', "::before": { backgroundColor: '#1c252e' } }
                            }}
                            selectedKey={appStateContext?.state.activeRightPaneTab}
                            onLinkClick={item => handleRightPaneTabClick(item?.props.itemKey!)}
                            headersOnly={true}
                        >
                            <PivotItem headerText="Sources" itemKey={RIGHT_PANE_TAB_SOURCES_NAME} />
                            <PivotItem headerText="Citations" itemKey={RIGHT_PANE_TAB_CITATIONS_NAME} />
                            <PivotItem headerText="Export" itemKey={RIGHT_PANE_TAB_EXPORT_NAME} />
                        </Pivot>
                        <div className={styles["right-pane__icon-close"]} onClick={(e) => toggleRightPane(e)}>
                            <IconCloseRightPane />
                        </div>
                    </div>
                    {
                        appStateContext?.state.activeRightPaneTab == RIGHT_PANE_TAB_SOURCES_NAME &&
                        <div className={`${styles["right-pane__tab"]} ${styles["right-pane__tab__sources"]}`}>
                            <div className={styles["right-pane__tab__sources__block__tenant-tooltip"]}>
                                <div className={styles["right-pane__tab__sources__block__tenant-tooltip__bubble"]}>
                                    <div className={styles["right-pane__tab__sources__block__tenant-tooltip__text"]}>{tenantSettings?.tooltip.info}</div>
                                    {
                                        tenantSettings?.sharepointConfigs.informationLink
                                        && tenantSettings?.sharepointConfigs.informationLink.trim().length > 0
                                        && <div>
                                            <a className={styles["right-pane__tab__sources__block__tenant-tooltip__link"]} href={tenantSettings?.sharepointConfigs.informationLink} target={"_blank"}>Open directory</a>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={styles["right-pane__tab__sources__block__web-browsing"]}>
                                <div className={styles["right-pane__tab__sources__block__web-browsing__heading"]}>
                                    <div className={styles["right-pane__tab__sources__block__web-browsing__heading__text"]}>Web browsing:</div>
                                    <IconTooltipInfo />
                                </div>
                                <ThemeProvider theme={customWebBrowingToggleTheme}>
                                    <Toggle
                                        onText="Enabled"
                                        offText="Disabled"
                                        styles={{
                                            root: { marginBottom: '0px' },
                                            container: { alignItems: 'center' },
                                            label: { fontWeight: 400, fontSize: '14px', lineHeight: '22px', color: '#1c252e' },
                                        }}
                                        checked={appStateContext?.state.isWebBrowsingEnabled}
                                        onChange={(event, checked?: boolean) => { setIsWebBrowsingEnabled(checked ?? false); }}
                                    />
                                </ThemeProvider>
                            </div>
                            {/* <Separator />
                        <div style={{ padding: '0px 25px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
                                <div style={{ fontWeight: 600, fontSize: '13px', lineHeight: '18px', color: '#727a90' }}>External data sources:</div>
                                <IconTooltipInfo />
                            </div>
                            <div>
                                <select style={{ width: '100%', height: '40px', borderRadius: '8px', border: '1px solid rgba(145, 158, 171, 0.2)', padding: '0px 15px' }} disabled>
                                    <option value="none">None</option>
                                </select>
                            </div>
                        </div> */}
                            <Separator styles={{ root: { padding: '2px 0px 4px', height: '34px' } }} />
                            {
                                appStateContext.state.tenantSettings?.visibility.fileUploader
                                && <div className={styles["right-pane__tab__sources__block__file-uploader"]}>
                                    <FileUpload />
                                </div>
                            }
                        </div>
                    }
                    {
                        appStateContext?.state.activeRightPaneTab == RIGHT_PANE_TAB_CITATIONS_NAME &&
                        <div className={`${styles["right-pane__tab"]} ${styles["right-pane__tab__citations"]}`}>
                            <Citation />
                        </div>
                    }
                    {
                        appStateContext?.state.activeRightPaneTab == RIGHT_PANE_TAB_EXPORT_NAME &&
                        <div className={`${styles["right-pane__tab"]} ${styles["right-pane__tab__export"]}`}>
                            <ChatExport />
                        </div>
                    }
                    <div className={styles["right-pane__block_end"]}></div>
                </div>
                <KnowledgeDomainModal setKnowledgeDomain={setKnowledgeDomain} />
            </div>
        </div>
    )
};

export default Layout;