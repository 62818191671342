import { Conversation } from '../api';

import { RIGHT_PANE_TAB_SOURCES_NAME } from '../constants/rightPane';

import { Action, AppState } from './AppProvider';

import { useChatStore } from "../store/Chat.store";

export const appStateReducer = (state: AppState, action: Action): AppState => {
    switch (action.type) {
        case 'UPDATE_CURRENT_CHAT':
            return { ...state, currentChat: action.payload };
        case 'UPDATE_CHAT_HISTORY_LOADING_STATE':
            return { ...state, chatHistoryLoadingState: action.payload };
        case 'UPDATE_CHAT_HISTORY':
            if (!state.chatHistory || !state.currentChat) {
                return state;
            }
            let conversationIndex = state.chatHistory.findIndex(conv => conv.id === action.payload.id);
            if (conversationIndex !== -1) {
                let updatedChatHistory = [...state.chatHistory];
                updatedChatHistory[conversationIndex] = state.currentChat;
                return { ...state, chatHistory: updatedChatHistory };
            } else {
                return { ...state, chatHistory: [...state.chatHistory, action.payload] };
            }
        case 'UPDATE_CHAT_TITLE':
            if (!state.chatHistory) {
                return { ...state, chatHistory: [] };
            }
            let updatedChats = state.chatHistory.map(chat => {
                if (chat.id === action.payload.id) {
                    if (state.currentChat?.id === action.payload.id) {
                        state.currentChat.title = action.payload.title;
                    }
                    //TODO: make api call to save new title to DB
                    return { ...chat, title: action.payload.title };
                }
                return chat;
            });
            return { ...state, chatHistory: updatedChats };
        case 'DELETE_CHAT_ENTRY':
            let filteredChat: Conversation[] = [];
            if (state.chatHistory) {
                filteredChat = state.chatHistory.filter(chat => chat.id !== action.payload);
            }
            useChatStore.setState({ userPrompt: "" });
            return {
                ...state,
                chatHistory: filteredChat,
                currentChat: null,
                followUpQuestions: [],
                activeRightPaneTab: RIGHT_PANE_TAB_SOURCES_NAME,
                expandedRightPane: false,
                currentKnowledgeDomain: state.initialKnowledgeDomain,
            };
        case 'DELETE_CHAT_HISTORY':
            //TODO: make api call to delete all conversations from DB
            return { ...state, chatHistory: [], filteredChatHistory: [], currentChat: null };
        case 'DELETE_CURRENT_CHAT_MESSAGES':
            //TODO: make api call to delete current conversation messages from DB
            if (!state.currentChat || !state.chatHistory) {
                return state;
            }
            const updatedCurrentChat = {
                ...state.currentChat,
                messages: []
            };
            return {
                ...state,
                currentChat: updatedCurrentChat
            };
        case 'FETCH_CHAT_HISTORY':
            return { ...state, chatHistory: action.payload };
        case 'SET_COSMOSDB_STATUS':
            return { ...state, isCosmosDBAvailable: action.payload };
        case 'FETCH_FRONTEND_SETTINGS':
            return { ...state, frontendSettings: action.payload };
        case 'SET_FEEDBACK_STATE':
            return {
                ...state,
                feedbackState: {
                    ...state.feedbackState,
                    [action.payload.answerId]: action.payload.feedback,
                },
            };
        case "FETCH_TENANT_SETTINGS":
            return { ...state, tenantSettings: action.payload };
        case "SET_KNOWLEDGE_DOMAIN":
            return { ...state, currentKnowledgeDomain: action.payload };
        case "SET_IS_VALID_CURRENT_KNOWLEDGE_DOMAIN":
            return { ...state, isValidCurrentKnowledgeDomain: action.payload };
        case "SET_INITIAL_KNOWLEDGE_DOMAIN":
            return { ...state, initialKnowledgeDomain: action.payload };
        case 'FETCH_USER':
            return { ...state, user: action.payload };
        case 'SET_ACTIVE_CITATION':
            return { ...state, activeCitation: action.payload };
        case 'TOGGLE_LEFT_PANE':
            return { ...state, expandedLeftPane: action.payload };
        case 'TOGGLE_RIGHT_PANE':
            return { ...state, expandedRightPane: action.payload };
        case "SET_ACTIVE_RIGHT_PANE_TAB":
            return { ...state, activeRightPaneTab: action.payload };
        case "SET_IS_SINGLE_KNOWLEDGE_DOMAIN":
            return { ...state, isSingleKnowledgeDomain: action.payload };
        case "SET_OPEN_KNOWLEDGE_DOMAIN_MODAL":
            if (action.payload && state.isSingleKnowledgeDomain) {
                return state;
            }
            return { ...state, openedKnowledgeDomainModal: action.payload };
        case "TRIGGERING_NEW_CONVERSATION_PROMPT":
            if (action.payload && action.payload.length > 0) {
                return { ...state, newConversationTriggeringPrompt: action.payload };
            }
            return { ...state, newConversationTriggeringPrompt: null };
        case "SET_FOLLOW_UP_QUESTIONS":
            return { ...state, followUpQuestions: action.payload };
        case "UPDATE_UPLOADED_FILES":
            return { ...state, uploadedFiles: action.payload };
        case "SET_WEBBROWSING_ENABLED":
            return { ...state, isWebBrowsingEnabled: action.payload };
        default:
            return state;
    };
};