import { UserInfo, ConversationRequest, Conversation, ChatMessage, CosmosDBHealth, CosmosDBStatus, TenantSettings, UploadedFile, UploadFileStatus, ExportFileFormat } from "./models";
import { chatHistorySampleData } from "../constants/chatHistory";

export async function conversationApi(options: ConversationRequest, abortSignal: AbortSignal): Promise<Response> {
    const response = await fetch("/conversation", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            knowledge_domain: options.knowledgeDomain,
            messages: options.messages,
        }),
        signal: abortSignal
    });

    return response;
}

export async function webSearchApi(options: ConversationRequest, abortSignal: AbortSignal): Promise<Response> {
    const response = await fetch("/conversation_web_search", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            messages: options.messages
        }),
        signal: abortSignal
    });

    return response;
}

export async function getUserInfo(): Promise<UserInfo[]> {
    const response = await fetch('/.auth/me');
    if (!response.ok) {
        console.log("No identity provider found. Access to chat will be blocked.")
        return [];
    }

    const payload = await response.json();
    return payload;
}

// export const fetchChatHistoryInit = async (): Promise<Conversation[] | null> => {
export const fetchChatHistoryInit = (): Conversation[] | null => {
    // Make initial API call here

    // return null;
    return chatHistorySampleData;
}

export const historyList = async (offset = 0): Promise<Conversation[] | null> => {
    const response = await fetch(`/history/list?offset=${offset}`, {
        method: "GET",
    }).then(async (res) => {
        const payload = await res.json();
        if (!Array.isArray(payload)) {
            console.error("There was an issue fetching your data.");
            return null;
        }
        const conversations: Conversation[] = await Promise.all(payload.map(async (conv: any) => {
            let convMessages: ChatMessage[] = [];
            convMessages = await historyRead(conv.id)
                .then((res) => {
                    return res
                })
                .catch((err) => {
                    console.error("error fetching messages: ", err)
                    return []
                })
            const conversation: Conversation = {
                id: conv.id,
                title: conv.title,
                date: conv.createdAt,
                messages: convMessages,
                knowledgeDomain: conv.knowledgeDomain
            };
            return conversation;
        }));
        return conversations;
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return null
    })

    return response
}

export const historyRead = async (convId: string): Promise<ChatMessage[]> => {
    const response = await fetch("/history/read", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then(async (res) => {
            if (!res) {
                return []
            }
            const payload = await res.json();
            let messages: ChatMessage[] = [];
            if (payload?.messages) {
                payload.messages.forEach((msg: any) => {
                    const message: ChatMessage = {
                        id: msg.id,
                        role: msg.role,
                        date: msg.createdAt,
                        content: msg.content,
                        webBrowserContent: msg.webBrowserContent ?? '',
                        feedback: msg.feedback ?? undefined,
                        citations: msg.citations ?? [],
                        webBrowserCitations: msg.webBrowserCitations ?? [],
                    }
                    messages.push(message)
                });
            }
            return messages;
        }).catch((err) => {
            console.error("There was an issue fetching your data.");
            return []
        })
    return response
}

export const historyGenerate = async (options: ConversationRequest, abortSignal: AbortSignal): Promise<Response> => {
    let body;
    if (options.conversationId && options.conversationId.length > 0) {
        body = JSON.stringify({
            conversation_id: options.conversationId,
            knowledge_domain: options.knowledgeDomain,
            messages: options.messages,
        });
    } else {
        body = JSON.stringify({
            knowledge_domain: options.knowledgeDomain,
            messages: options.messages,
        });
    }
    const response = await fetch("/history/generate", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: body,
        signal: abortSignal
    }).then((res) => {
        return res;
    })
        .catch((err) => {
            console.error("There was an issue fetching your data.");
            return new Response;
        })
    return response;
};

export const historyUpdate = async (messages: ChatMessage[], convId: string): Promise<Response> => {
    const response = await fetch("/history/update", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
            messages: messages,
        }),
        headers: {
            "Content-Type": "application/json"
        },
    }).then(async (res) => {
        return res;
    })
        .catch((err) => {
            console.error("There was an issue fetching your data.");
            let errRes: Response = {
                ...new Response,
                ok: false,
                status: 500,
            };
            return errRes;
        })
    return response;
}

export const historyDelete = async (convId: string): Promise<Response> => {
    const response = await fetch("/history/delete", {
        method: "DELETE",
        body: JSON.stringify({
            conversation_id: convId,
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then((res) => {
            return res
        })
        .catch((err) => {
            console.error("There was an issue fetching your data.");
            let errRes: Response = {
                ...new Response,
                ok: false,
                status: 500,
            }
            return errRes;
        })
    return response;
}

export const historyDeleteAll = async (): Promise<Response> => {
    const response = await fetch("/history/delete_all", {
        method: "DELETE",
        body: JSON.stringify({}),
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then((res) => {
            return res
        })
        .catch((err) => {
            console.error("There was an issue fetching your data.");
            let errRes: Response = {
                ...new Response,
                ok: false,
                status: 500,
            }
            return errRes;
        })
    return response;
}

export const historyClear = async (convId: string): Promise<Response> => {
    const response = await fetch("/history/clear", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then((res) => {
            return res
        })
        .catch((err) => {
            console.error("There was an issue fetching your data.");
            let errRes: Response = {
                ...new Response,
                ok: false,
                status: 500,
            }
            return errRes;
        })
    return response;
}

export const historyRename = async (convId: string, title: string): Promise<Response> => {
    const response = await fetch("/history/rename", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
            title: title
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then((res) => {
            return res
        })
        .catch((err) => {
            console.error("There was an issue fetching your data.");
            let errRes: Response = {
                ...new Response,
                ok: false,
                status: 500,
            }
            return errRes;
        })
    return response;
}

export const historyEnsure = async (): Promise<CosmosDBHealth> => {
    const response = await fetch("/history/ensure", {
        method: "GET",
    })
        .then(async res => {
            let respJson = await res.json();
            let formattedResponse;
            if (respJson.message) {
                formattedResponse = CosmosDBStatus.Working
            } else {
                if (res.status === 500) {
                    formattedResponse = CosmosDBStatus.NotWorking
                } else if (res.status === 401) {
                    formattedResponse = CosmosDBStatus.InvalidCredentials
                } else if (res.status === 422) {
                    formattedResponse = respJson.error
                } else {
                    formattedResponse = CosmosDBStatus.NotConfigured
                }
            }
            if (!res.ok) {
                return {
                    cosmosDB: false,
                    status: formattedResponse
                }
            } else {
                return {
                    cosmosDB: true,
                    status: formattedResponse
                }
            }
        })
        .catch((err) => {
            console.error("There was an issue fetching your data.");
            return {
                cosmosDB: false,
                status: err
            }
        })
    return response;
}

export const frontendSettings = async (): Promise<Response | null> => {
    const response = await fetch("/frontend_settings", {
        method: "GET",
    }).then((res) => {
        return res.json()
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return null
    })

    return response
}

export const fetchTenantSettings = async (): Promise<TenantSettings | null> => {

    try {
        const response = await fetch("/tenant_settings", { method: "GET" });

        if (!response.ok) {
            throw new Error("There was an issue fetching the tenant settings.");
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching tenant settings:", error);
        return null;
    }
}

export const historyMessageFeedback = async (messageId: string, feedback: string): Promise<Response> => {
    const response = await fetch("/history/message_feedback", {
        method: "POST",
        body: JSON.stringify({
            message_id: messageId,
            message_feedback: feedback
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then((res) => {
            return res
        })
        .catch((err) => {
            console.error("There was an issue logging feedback.");
            let errRes: Response = {
                ...new Response,
                ok: false,
                status: 500,
            }
            return errRes;
        })
    return response;
}

export const uploadFileToAzureBlobStorage = async (file: File, containerName: string): Promise<Array<string>> => {
    const container = containerName.trim();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("container_name", container);

    return fetch("/upload", { method: 'POST', body: formData, })
        .then(
            response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            }
        );
};

export const checkIndexStatus = async (filename: string, indexName: string): Promise<UploadFileStatus> => {
    const index = indexName.trim();
    return await fetch(
        `/check-index-status`,
        {
            cache: "no-cache",
            method: "POST",
            body: JSON.stringify({ filename: filename, index_name: index }),
            headers: {
                "Content-Type": "application/json"
            },
        }
    )
        .then(
            response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            }
        );
}

export const deleteFileFromAzureBlobStorage = async (filename: string, containerName: string): Promise<Response> => {
    const container = containerName.trim();
    return await fetch(
        `/upload`,
        {
            method: "DELETE",
            body: JSON.stringify({ filename: filename, container_name: container }),
            headers: {
                "Content-Type": "application/json"
            },
        }
    );
};

export const fetchUploadedFiles = async (containerName: string, indexName: string): Promise<Array<UploadedFile>> => {
    const container = containerName.trim();
    const index = indexName.trim();
    return await fetch(
        `/uploaded-files?container_name=${container}&index_name=${index}`,
        { cache: "no-cache", method: "GET" }
    )
        .then(
            response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            }
        );
};

export const exportConversation = async (fileFormat: ExportFileFormat, conversation: Conversation): Promise<Response> => {
    return await fetch(
        `/export`,
        {
            method: "POST",
            body: JSON.stringify({ file_format: fileFormat, conversation }),
            headers: {
                "Content-Type": "application/json"
            },
        }
    );
};
