import { AskResponse, Citation } from "../../api";
import { cloneDeep } from "lodash-es";
import uuid from 'react-uuid';

type ParsedAnswer = {
    citations: Citation[];
    markdownFormatText: string;
};

const enumerateCitations = (citations: Citation[]) => {
    const filepathMap = new Map();
    for (const citation of citations) {
        const { filepath } = citation;
        let part_i = 1
        if (filepathMap.has(filepath)) {
            part_i = filepathMap.get(filepath) + 1;
        }
        filepathMap.set(filepath, part_i);
        citation.part_index = part_i;
    }
    return citations;
}

export function parseAnswer({ answer, citations }: AskResponse, regEx: RegExp, citationIndexPosition: number): ParsedAnswer {
    if (!citations) {
        return {
            citations: [],
            markdownFormatText: answer
        };
    }

    let answerText = answer;
    const citationLinks = answer.match(regEx);

    let filteredCitations = [] as Citation[];
    let citationReindex = 0;

    if (!citationLinks) {
        return {
            citations: [],
            markdownFormatText: answerText
        };
    }

    for (const link of citationLinks) {
        // Extract citation index, handling [doc_X], [docs-1-28], and [1] formats
        let citationIndex = link.slice(1, link.length - 1); // Remove brackets
        if (citationIndex.startsWith('doc_')) {
            citationIndex = citationIndex.substring(4); // Remove 'doc_'
        } else if (citationIndex.startsWith('docs-')) {
            citationIndex = citationIndex.substring(5); // Remove 'docs-'
        } else if (citationIndex.startsWith('docs_')) {
            citationIndex = citationIndex.substring(5); // Remove 'docs_'
        }
        // Get the first number in case of hyphenated format
        citationIndex = citationIndex.split(/[-_]/)[0];
        
        // Handle simple numeric citations
        if (!isNaN(Number(citationIndex))) {
            let citation = cloneDeep(citations[Number(citationIndex) - 1]) as Citation;

            if (!citation) {
                // If citation is not found in the citations list, remove the cite from the answer
                answerText = answerText.replace(link, "");
            }

            if (!filteredCitations.find((c) => c.id === citationIndex) && citation) {
                answerText = answerText.replaceAll(link, `[${++citationReindex}-${uuid()}](#citation)`);
                citation.id = citationIndex; // original doc index to de-dupe
                citation.reindex_id = citationReindex.toString(); // reindex from 1 for display
                filteredCitations.push(citation);
            }
        }
    }

    filteredCitations = enumerateCitations(filteredCitations);

    return {
        citations: filteredCitations,
        markdownFormatText: answerText
    };
}
