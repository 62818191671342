import { IconButton, Modal, Stack, Text } from "@fluentui/react";
import { useDropzone } from 'react-dropzone';
import React, { useCallback } from 'react';
import styles from "./FileUploadModal.module.css";

interface FileUploadModalProps {
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
    onUploadFiles: (files: File[]) => void;
}

const FileUploadModal: React.FunctionComponent<FileUploadModalProps> = ({ isOpen, setOpen, onUploadFiles }) => {
    const closeModal = () => {
        setOpen(false);
    }

    const onDrop = useCallback(
        (files: File[]) => {
            if (files.length === 0) {
                return;
            }

            const currentDate = new Date()
                .toISOString()
                .split('T')[0];

            let modifiedFiles = [
                ...files.map(
                    file => {
                        const modifiedFile = new File([file], `${currentDate} ${file.name}`);
                        return modifiedFile;
                    }
                )
            ];

            onUploadFiles(modifiedFiles);
            closeModal();
        },
        []
    );

    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        noDragEventsBubbling: true,
        accept: {
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        },
        onDrop: onDrop,
    });

    return (
        <Modal
            isOpen={isOpen}
            onDismiss={closeModal}
            isBlocking={true}
            containerClassName={styles.uploadModalContainer}
        >
            <div className={styles.modalHeader}>
                <Stack horizontal horizontalAlign="space-between" verticalAlign="center" tokens={{ childrenGap: 10 }}>
                    <Text variant="large">Upload File(s)</Text>
                    <IconButton
                        iconProps={{
                            iconName: 'Cancel',
                            styles: {
                                root: { color: '#000000' }
                            }
                        }}
                        ariaLabel="Close modal"
                        onClick={closeModal}
                    />
                </Stack>
            </div>

            <div className={styles.modalBody}>
                <Stack horizontal horizontalAlign="space-between" verticalAlign="center" tokens={{ childrenGap: 10 }}>
                    <div {...getRootProps({ className: styles.dropzone })}>
                        <input {...getInputProps()} />
                        <div className={styles.dragText} aria-label='Drag & Drop Files Here, or Click to Upload. (Only pdf, doc and docx files are supported)'>
                            <Text>Drag & Drop Files Here, or Click to Upload. (Only pdf, doc and docx files are supported)</Text>
                        </div>
                    </div>
                </Stack>
            </div>
        </Modal>
    );
}

export default FileUploadModal;