import styles from "./KnowledgeDomainButton.module.css";

import { useContext } from "react";

import { AppStateContext } from "../../state/AppProvider";

import IconKnowledgeDomainDefault from "./IconKnowledgeDomain";

const IconChevronSort: React.FunctionComponent<{ className?: string | undefined; }> = ({ className }) => {
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 21L6.75 15.75L7.8075 14.6925L12 18.8775L16.1925 14.6925L17.25 15.75L12 21ZM12 3L17.25 8.25L16.1925 9.3075L12 5.1225L7.8075 9.3075L6.75 8.25L12 3Z" fill="#006C9C" />
        </svg>
    );
};

interface KnowledgeDomainButtonProps {
    openModal: () => void;
    isDocked?: boolean;
};

const KnowledgeDomainButton: React.FunctionComponent<KnowledgeDomainButtonProps> = ({ openModal, isDocked = false }) => {
    const appStateContext = useContext(AppStateContext);

    return (
        <div className={styles["knowledge-domain-button__container"]} onClick={openModal}>
            <div className={styles["knowledge-domain-button__icon__container"]}>
                {
                    appStateContext?.state.currentKnowledgeDomain?.iconUrl?.length ?? 0 > 0 ?
                        <img className={styles["knowledge-domain-button__icon"]} src={appStateContext?.state.currentKnowledgeDomain?.iconUrl} />
                        : <IconKnowledgeDomainDefault />
                }
            </div>
            <div className={`${styles["knowledge-domain-button__text"]} ${isDocked ? styles["knowledge-domain-button__text--docked"] : ''}`}>{appStateContext?.state.currentKnowledgeDomain?.label}</div>
            {
                !appStateContext?.state.isSingleKnowledgeDomain
                && <IconChevronSort className={`${styles["knowledge-domain-button__chevron"]} ${isDocked ? styles["knowledge-domain-button__chevron--docked"] : ''}`} />
            }
        </div>
    );
};

export default KnowledgeDomainButton;