import { createContext, useReducer, ReactNode, useEffect, useRef } from 'react';
import { appStateReducer } from './AppReducer';
import {
    Conversation,
    ChatHistoryLoadingState,
    CosmosDBHealth,
    historyList,
    historyEnsure,
    CosmosDBStatus,
    frontendSettings,
    FrontendSettings,
    Feedback,
    fetchTenantSettings,
    TenantSettings,
    KnowledgeDomain,
    UserInfo,
    getUserInfo,
    Citation,
    UploadedFile,
} from '../api';

import { RIGHT_PANE_TAB_SOURCES_NAME } from "../constants/rightPane";

export interface AppState {
    user: UserInfo | null,
    chatHistoryLoadingState: ChatHistoryLoadingState;
    isCosmosDBAvailable: CosmosDBHealth;
    chatHistory: Conversation[] | null;
    filteredChatHistory: Conversation[] | null;
    currentChat: Conversation | null;
    frontendSettings: FrontendSettings | null;
    feedbackState: { [answerId: string]: Feedback.Neutral | Feedback.Positive | Feedback.Negative; };
    tenantSettings: TenantSettings | null;
    currentKnowledgeDomain: KnowledgeDomain | null;
    isValidCurrentKnowledgeDomain: boolean;
    initialKnowledgeDomain: KnowledgeDomain | null;
    activeCitation: Citation | null;
    expandedLeftPane: boolean;
    expandedRightPane: boolean;
    activeRightPaneTab: string;
    isSingleKnowledgeDomain: boolean;
    openedKnowledgeDomainModal: boolean;
    newConversationTriggeringPrompt: string | null;
    followUpQuestions: string[];
    uploadedFiles: UploadedFile[];
    isWebBrowsingEnabled: boolean;
};

export type Action =
    | { type: 'SET_COSMOSDB_STATUS', payload: CosmosDBHealth }
    | { type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState }
    | { type: 'UPDATE_CURRENT_CHAT', payload: Conversation | null }
    | { type: 'UPDATE_FILTERED_CHAT_HISTORY', payload: Conversation[] | null }
    | { type: 'UPDATE_CHAT_HISTORY', payload: Conversation } // API Call
    | { type: 'UPDATE_CHAT_TITLE', payload: Conversation } // API Call
    | { type: 'DELETE_CHAT_ENTRY', payload: string } // API Call
    | { type: 'DELETE_CHAT_HISTORY' }  // API Call
    | { type: 'DELETE_CURRENT_CHAT_MESSAGES', payload: string }  // API Call
    | { type: 'FETCH_CHAT_HISTORY', payload: Conversation[] | null }  // API Call
    | { type: 'FETCH_FRONTEND_SETTINGS', payload: FrontendSettings | null }  // API Call
    | { type: 'SET_FEEDBACK_STATE', payload: { answerId: string; feedback: Feedback.Positive | Feedback.Negative | Feedback.Neutral } }
    | { type: 'GET_FEEDBACK_STATE', payload: string }
    | { type: "FETCH_TENANT_SETTINGS", payload: TenantSettings | null }
    | { type: "SET_KNOWLEDGE_DOMAIN", payload: KnowledgeDomain | null }
    | { type: "SET_IS_VALID_CURRENT_KNOWLEDGE_DOMAIN", payload: boolean }
    | { type: "SET_INITIAL_KNOWLEDGE_DOMAIN", payload: KnowledgeDomain | null }
    | { type: 'FETCH_USER', payload: UserInfo | null }
    | { type: 'UPDATE_USER', payload: UserInfo }
    | { type: 'SET_ACTIVE_CITATION', payload: Citation | null }
    | { type: "TOGGLE_LEFT_PANE", payload: boolean }
    | { type: "TOGGLE_RIGHT_PANE", payload: boolean }
    | { type: "SET_ACTIVE_RIGHT_PANE_TAB", payload: string }
    | { type: "SET_IS_SINGLE_KNOWLEDGE_DOMAIN", payload: boolean }
    | { type: "SET_OPEN_KNOWLEDGE_DOMAIN_MODAL", payload: boolean }
    | { type: "TRIGGERING_NEW_CONVERSATION_PROMPT", payload: string | null }
    | { type: "SET_FOLLOW_UP_QUESTIONS", payload: string[] }
    | { type: "UPDATE_UPLOADED_FILES", payload: UploadedFile[] }
    | { type: "SET_WEBBROWSING_ENABLED", payload: boolean }
    ;

const initialState: AppState = {
    user: null,
    chatHistoryLoadingState: ChatHistoryLoadingState.Loading,
    chatHistory: null,
    filteredChatHistory: null,
    currentChat: null,
    isCosmosDBAvailable: {
        cosmosDB: false,
        status: CosmosDBStatus.NotConfigured,
    },
    frontendSettings: null,
    tenantSettings: null,
    currentKnowledgeDomain: null,
    isValidCurrentKnowledgeDomain: false,
    initialKnowledgeDomain: null,
    feedbackState: {},
    activeCitation: null,
    expandedLeftPane: false,
    expandedRightPane: false,
    activeRightPaneTab: RIGHT_PANE_TAB_SOURCES_NAME,
    isSingleKnowledgeDomain: true,
    openedKnowledgeDomainModal: false,
    newConversationTriggeringPrompt: null,
    followUpQuestions: [],
    uploadedFiles: [],
    isWebBrowsingEnabled: false,
};

export const AppStateContext = createContext<{
    state: AppState;
    dispatch: React.Dispatch<Action>;
} | undefined>(undefined);

type AppStateProviderProps = {
    children: ReactNode;
};

export const AppStateProvider: React.FC<AppStateProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(appStateReducer, initialState);
    const previousKnowledgeDomain = useRef<KnowledgeDomain>();

    const isValidCurrentKnowledgeDomain = (): boolean => {
        if (!state.tenantSettings || !state.currentKnowledgeDomain) {
            return false;
        }

        const knowledgeDomains = state.tenantSettings.knowledgeDomains.filter(x => !x.disabled);
        const currentKnowledgeDomain = state.currentKnowledgeDomain;
        const isValid = knowledgeDomains
            .some(x =>
                x.indexName.trim().length > 0
                && x.indexName.trim() == currentKnowledgeDomain.indexName.trim()
            );

        return isValid;
    };

    useEffect(() => {
        // Check for cosmosdb config and fetch initial data here
        const fetchChatHistory = async (offset = 0): Promise<Conversation[] | null> => {
            const result = await historyList(offset).then((response) => {
                if (response) {
                    dispatch({ type: 'FETCH_CHAT_HISTORY', payload: response });
                } else {
                    dispatch({ type: 'FETCH_CHAT_HISTORY', payload: null });
                }
                return response;
            })
                .catch((err) => {
                    dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Fail });
                    dispatch({ type: 'FETCH_CHAT_HISTORY', payload: null });
                    console.error("There was an issue fetching your data.");
                    return null;
                });
            return result;
        };

        const getHistoryEnsure = async () => {
            dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Loading });
            historyEnsure().then((response) => {
                if (response?.cosmosDB) {
                    fetchChatHistory()
                        .then((res) => {
                            if (res) {
                                dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Success });
                                dispatch({ type: 'SET_COSMOSDB_STATUS', payload: response });
                            } else {
                                dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Fail });
                                dispatch({ type: 'SET_COSMOSDB_STATUS', payload: { cosmosDB: false, status: CosmosDBStatus.NotWorking } });
                            }
                        })
                        .catch((err) => {
                            dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Fail });
                            dispatch({ type: 'SET_COSMOSDB_STATUS', payload: { cosmosDB: false, status: CosmosDBStatus.NotWorking } });
                        });
                } else {
                    dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Fail });
                    dispatch({ type: 'SET_COSMOSDB_STATUS', payload: response });
                }
            })
                .catch((err) => {
                    dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Fail });
                    dispatch({ type: 'SET_COSMOSDB_STATUS', payload: { cosmosDB: false, status: CosmosDBStatus.NotConfigured } });
                });
        };
        getHistoryEnsure();
    }, []);

    useEffect(() => {
        const getFrontendSettings = async () => {
            frontendSettings().then((response) => {
                dispatch({ type: 'FETCH_FRONTEND_SETTINGS', payload: response as FrontendSettings });
            })
                .catch((err) => {
                    console.error("There was an issue fetching your data.");
                })
        }
        getFrontendSettings();
    }, []);

    useEffect(() => {
        const getTenantSettings = async () => {
            fetchTenantSettings().then((response) => {
                if (!response) {
                    return;
                }

                dispatch({ type: 'FETCH_TENANT_SETTINGS', payload: response as TenantSettings });
            });
        };
        getTenantSettings();
    }, []);

    useEffect(
        () => {
            const fetchUser = async () => {
                getUserInfo()
                    .then(
                        (response: UserInfo[]) => {
                            if (!response) {
                                return;
                            }
                            dispatch({ type: "FETCH_USER", payload: response[0] });
                        }
                    );
            };
            fetchUser();
        },
        []
    );

    useEffect(
        () => {
            const knowledgeDomains = state.tenantSettings?.knowledgeDomains ?? [];
            if (knowledgeDomains.length < 1) {
                return;
            }
            const activeDomains = knowledgeDomains.filter(x => !x.disabled);
            if (activeDomains.length > 0) {
                const onlyOneDomainAvailable: boolean = activeDomains.length == 1;
                dispatch({ type: "SET_IS_SINGLE_KNOWLEDGE_DOMAIN", payload: onlyOneDomainAvailable });
                dispatch({ type: "SET_INITIAL_KNOWLEDGE_DOMAIN", payload: activeDomains[0] });
                dispatch({ type: "SET_KNOWLEDGE_DOMAIN", payload: activeDomains[0] });
            }
            return;
        },
        [state.tenantSettings?.knowledgeDomains]
    );

    useEffect(
        () => {
            const isCurrentValid = isValidCurrentKnowledgeDomain();
            dispatch({ type: "SET_IS_VALID_CURRENT_KNOWLEDGE_DOMAIN", payload: isCurrentValid });

            if (
                !state.currentKnowledgeDomain
                || !state.currentKnowledgeDomain?.indexName
                || state.currentKnowledgeDomain.indexName.length < 1
                || !state.currentKnowledgeDomain?.containerName
                || state.currentKnowledgeDomain.containerName.length < 1
            ) {
                return;
            }

            // Logic to update uploaded files if knowledge domain changes //

            // Safeguard for internal processing
            const previous = previousKnowledgeDomain.current;
            previousKnowledgeDomain.current = state.currentKnowledgeDomain;

            if (
                isCurrentValid
                && previous
                && previous.containerName != state.currentKnowledgeDomain.containerName
                && previous.indexName != state.currentKnowledgeDomain.indexName
            ) {
                dispatch({ type: "UPDATE_UPLOADED_FILES", payload: [] });
            }
        },
        [state.currentKnowledgeDomain]
    );

    return (
        <AppStateContext.Provider value={{ state, dispatch }}>
            {children}
        </AppStateContext.Provider>
    );
};
