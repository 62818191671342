import styles from "./ChatHistoryListItem.module.css"

import { Conversation } from "../../api";

import { historyDelete, historyRename } from "../../api";

import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AppStateContext } from "../../state/AppProvider";

import { useBoolean } from "@fluentui/react-hooks";

import { Callout, Dialog, DialogType, DirectionalHint, IconButton } from "@fluentui/react";

const IconElipsis: React.FunctionComponent = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99999 9.33333C8.73637 9.33333 9.33332 8.73638 9.33332 8C9.33332 7.26362 8.73637 6.66667 7.99999 6.66667C7.26361 6.66667 6.66666 7.26362 6.66666 8C6.66666 8.73638 7.26361 9.33333 7.99999 9.33333Z" fill="#078DEE" />
            <path d="M7.99999 4.66667C8.73637 4.66667 9.33332 4.06971 9.33332 3.33333C9.33332 2.59695 8.73637 2 7.99999 2C7.26361 2 6.66666 2.59695 6.66666 3.33333C6.66666 4.06971 7.26361 4.66667 7.99999 4.66667Z" fill="#078DEE" />
            <path d="M7.99999 14C8.73637 14 9.33332 13.403 9.33332 12.6667C9.33332 11.9303 8.73637 11.3333 7.99999 11.3333C7.26361 11.3333 6.66666 11.9303 6.66666 12.6667C6.66666 13.403 7.26361 14 7.99999 14Z" fill="#078DEE" />
        </svg>
    );
};

interface ChatHistoryListItemProps {
    conversation: Conversation;
};

interface ChatHistoryListItemErrorMessage {
    show: boolean;
    text: string;
};

const ChatHistoryListItem: React.FunctionComponent<ChatHistoryListItemProps> = ({ conversation }) => {
    const appStateContext = useContext(AppStateContext);
    const isSelected = conversation.id === appStateContext?.state.currentChat?.id;
    const navigate = useNavigate();

    const handleSelectConversation = () => {
        if (isSelected) {
            return;
        }

        appStateContext?.dispatch({ type: "UPDATE_CURRENT_CHAT", payload: conversation });
        appStateContext?.dispatch({ type: "SET_KNOWLEDGE_DOMAIN", payload: conversation.knowledgeDomain ?? null });
        navigate("/chat");
    };

    const itemRef = useRef(null);
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);

    const initialErrorMessage: ChatHistoryListItemErrorMessage = {
        show: false,
        text: ""
    };
    const [errorMessage, setErrorMessage] = useState(initialErrorMessage);

    // RENAME / EDIT //
    const [isEditing, setIsEditing] = useState(false);
    const [editTitle, setEditTitle] = useState("");
    const [renameLoading, setRenameLoading] = useState(false);
    const [textFieldFocused, setTextFieldFocused] = useState(false);
    const textFieldRef = useRef<HTMLInputElement | null>(null);

    useEffect(
        () => {
            if (textFieldFocused && textFieldRef.current) {
                textFieldRef.current.focus();
                setTextFieldFocused(false);
            }
        },
        [textFieldFocused]
    );

    useEffect(
        () => {
            if (appStateContext?.state.currentChat?.id !== conversation.id) {
                setIsEditing(false);
                setTextFieldFocused(false);
                setEditTitle("");
            }
        },
        [appStateContext?.state.currentChat?.id, conversation.id]
    );

    const handleSaveEdit = async (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        if (errorMessage.show || renameLoading) {
            return;
        }

        if (editTitle == conversation.title) {
            setErrorMessage({ show: true, text: "Error: enter a new title to proceed" });
            setTimeout(
                () => {
                    setErrorMessage(initialErrorMessage);
                    setTextFieldFocused(true);
                    if (textFieldRef.current) {
                        textFieldRef.current.focus();
                    }
                },
                5000
            );
            return;
        }

        if (editTitle.length < 1) {
            setErrorMessage({ show: true, text: "Error: title cannot be empty" });
            setTimeout(
                () => {
                    setErrorMessage(initialErrorMessage);
                    setTextFieldFocused(true);
                    if (textFieldRef.current) {
                        textFieldRef.current.focus();
                    }
                },
                5000
            );
            return;
        }

        setRenameLoading(true);

        let response = await historyRename(conversation.id, editTitle);

        if (!response.ok) {
            setErrorMessage({ show: true, text: "Error: could not rename conversation" });
            setTimeout(
                () => {
                    setRenameLoading(false);
                    setTextFieldFocused(true);
                    setErrorMessage(initialErrorMessage);
                    if (textFieldRef.current) {
                        textFieldRef.current.focus();
                    }
                },
                5000
            );
            return;
        }

        setRenameLoading(false);
        setIsEditing(false);

        appStateContext?.dispatch({ type: "UPDATE_CHAT_TITLE", payload: { ...conversation, title: editTitle } });

        setTextFieldFocused(false);
        setEditTitle("");
    };

    const promptRename = async () => {
        setIsEditing(true);
        setTextFieldFocused(true);
        setEditTitle(conversation.title);
    };

    const cancelEditTitle = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setIsEditing(false);
        setTextFieldFocused(false);
        setEditTitle("");
    };

    const chatHistoryTitleOnChange = (event: any) => {
        setEditTitle(event.target.value);
    };

    const handleKeyPressEdit = (event: any) => {
        if (event.key === "Enter") {
            return handleSaveEdit(event);
        }

        if (event.key === "Escape") {
            cancelEditTitle(event);
            return;
        }
    };

    // DELETE //
    const [hideDeleteDialog, { toggle: toggleDeleteDialog }] = useBoolean(true);

    const handleOnDelete = async () => {
        let response = await historyDelete(conversation.id);

        if (!response.ok) {
            setErrorMessage({ show: true, text: "Error: could not delete conversation" });

            setTimeout(
                () => {
                    setErrorMessage(initialErrorMessage);
                },
                5000
            );

            toggleDeleteDialog();
        } else {
            appStateContext?.dispatch({ type: "DELETE_CHAT_ENTRY", payload: conversation.id });
            toggleDeleteDialog();
            navigate("/");
        }
    };

    return (
        <div
            key={conversation.id}
            aria-label="chat history item"
            className={`${styles["chat-history-list-item"]} ${isSelected ? styles["chat-history-list-item--selected"] : ""} ${isEditing ? styles["chat-history-list-item--selected--editing"] : ""}`}
            onClick={handleSelectConversation}
            title={conversation.title}
        >
            {
                isEditing ?
                    <form
                        className={styles["chat-history-list-item__form"]}
                        aria-label='edit title form'
                        onSubmit={handleSaveEdit}
                    >
                        <div className={styles["chat-history-list-item__container"]}>
                            <input
                                type="text"
                                className={styles["chat-history-list-item__form__input-title"]}
                                ref={textFieldRef}
                                autoFocus={textFieldFocused}
                                value={editTitle}
                                placeholder={conversation.title}
                                onChange={chatHistoryTitleOnChange}
                                onKeyDown={handleKeyPressEdit}
                                disabled={errorMessage.show || renameLoading}
                            />
                            <IconButton
                                role="button"
                                disabled={errorMessage.show || renameLoading}
                                onKeyDown={event => event.key === " " || event.key === "Enter" ? handleSaveEdit(event) : null}
                                onClick={handleSaveEdit}
                                aria-label="confirm new title"
                                iconProps={{ iconName: "CheckMark", styles: { root: { color: '#00ff00' } } }}
                            />
                            <IconButton
                                role="button"
                                disabled={errorMessage.show || renameLoading}
                                onKeyDown={event => event.key === " " || event.key === "Enter" ? cancelEditTitle(event) : null}
                                onClick={cancelEditTitle}
                                aria-label="cancel edit title"
                                iconProps={{ iconName: "Cancel", styles: { root: { color: '#ff0000' } } }}
                            />
                        </div>
                    </form>
                    : <div className={styles["chat-history-list-item__container"]}>
                        <div className={`${styles["chat-history-list-item__title"]} ${isSelected ? styles["chat-history-list-item__title--selected"] : ""}`}>{conversation.title}</div>
                        {
                            isSelected &&
                            <>
                                <div className={styles["chat-history-list-item__elipsis"]} onClick={toggleIsCalloutVisible} ref={itemRef}><IconElipsis /></div>
                                {
                                    isCalloutVisible &&
                                    <Callout
                                        className={styles["chat-history-list-item__contextual-menu"]}
                                        target={itemRef}
                                        isBeakVisible={false}
                                        onDismiss={toggleIsCalloutVisible}
                                        directionalHint={DirectionalHint.bottomRightEdge}
                                        styles={{ calloutMain: { padding: "4px", borderRadius: "12px" } }}
                                    >
                                        <div className={styles["chat-history-list-item__contextual-menu__item"]} onClick={() => { toggleIsCalloutVisible(); promptRename(); }}>Rename</div>
                                        <div className={styles["chat-history-list-item__contextual-menu__item"]} onClick={() => { toggleIsCalloutVisible(); toggleDeleteDialog(); }}>Delete</div>
                                    </Callout>
                                }
                            </>
                        }
                    </div>
            }
            {
                errorMessage.show &&
                <div className={styles["chat-history-list-item__message--error"]}>{errorMessage.text}</div>
            }
            <Dialog
                hidden={hideDeleteDialog}
                modalProps={{
                    isBlocking: true,
                    containerClassName: styles["chat-history-list-item__dialog-delete"],
                    overlay: { className: styles["chat-history-list-item__dialog-delete__overlay"] }
                }}
                onDismiss={toggleDeleteDialog}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: `Are you sure you want to delete the "${conversation.title}" session?`,
                    className: styles["chat-history-list-item__dialog-delete__content"],
                    styles: {
                        title: {
                            fontWeight: 600,
                            fontSize: "20px",
                            lineHeight: "30px",
                            color: "#1c252e",
                            padding: "16px 24px 20px 24px",
                            overflowWrap: "break-word",
                        }
                    },
                    showCloseButton: false,
                }}
            >
                <div className={styles["chat-history-list-item__dialog-delete__footer"]}>
                    <button
                        className={`${styles["chat-history-list-item__dialog-delete__button"]} ${styles["chat-history-list-item__dialog-delete__button-cancel"]}`}
                        onClick={toggleDeleteDialog}
                    >
                        Go back
                    </button>
                    <button
                        className={`${styles["chat-history-list-item__dialog-delete__button"]} ${styles["chat-history-list-item__dialog-delete__button-delete"]}`}
                        onClick={handleOnDelete}
                    >
                        Delete
                    </button>
                </div>
            </Dialog>
        </div>
    );
};

export default ChatHistoryListItem;