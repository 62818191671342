import styles from "./QuestionInput.module.css";

import { useContext, useEffect, useRef } from "react";

import { AppStateContext } from "../../state/AppProvider";

import { ChatState, useChatStore } from "../../store/Chat.store";

import { Callout, DefaultButton, DirectionalHint } from "@fluentui/react";

import { useBoolean } from "@fluentui/react-hooks";

interface QuestionInputProps {
    onSend: (question: string, id?: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    conversationId?: string;
    followUpQuestions?: string[];
}

const IconFollowUpChevronUp: React.FunctionComponent = () => {
    return (
        <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.17321 0.501689C4.31204 0.501363 4.44659 0.559458 4.55349 0.665889L8.11868 4.23546C8.37136 4.4878 8.40595 4.93848 8.19592 5.24208C7.98589 5.54568 7.61079 5.58723 7.3581 5.33489L4.17321 2.13655L0.988312 5.22066C0.865555 5.34044 0.708124 5.39648 0.550874 5.37638C0.393625 5.35628 0.249532 5.2617 0.150495 5.11358C0.0404883 4.96518 -0.0130615 4.76841 0.00272664 4.5706C0.0185138 4.37279 0.102209 4.19183 0.233682 4.07126L3.79886 0.623054C3.90884 0.533446 4.04064 0.490715 4.17321 0.501689Z" fill="#637381" />
        </svg>
    );
};

const QuestionInput: React.FunctionComponent<QuestionInputProps> = ({ onSend, disabled, placeholder, clearOnSend, conversationId, followUpQuestions }) => {
    const appStateContext = useContext(AppStateContext);
    const userPrompt = useChatStore((state: ChatState) => state.userPrompt);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const followUpContextMenuRef = useRef(null);
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);

    const adjustHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    useEffect(() => {
        adjustHeight(); // Adjust height on mount and when userPrompt changes
    }, [userPrompt]);

    const updateUserPrompt = (newUserPrompt: string) => {
        useChatStore.setState({ userPrompt: newUserPrompt });
    };

    const sendQuestion = () => {
        if (!userPrompt.trim() || disabled || !appStateContext?.state.isValidCurrentKnowledgeDomain) {
            return;
        }

        // Track the event using the Fathom global object
        if (window.fathom) {
            window.fathom.trackEvent('research_query_submitted', { eventData: { source: 'send_button' } });
            console.log('tracked');
        }

        if (conversationId) {
            onSend(userPrompt, conversationId);
        } else {
            onSend(userPrompt);
        }

        if (clearOnSend) {
            useChatStore.setState({ userPrompt: "" });
        }

        const openPromptButton = document.getElementById('openDialogButtonTopic');
        openPromptButton?.style.setProperty('display', 'block');
    };

    const onEnterPress = (event: React.KeyboardEvent<Element>) => {
        if (event.key === "Enter" && !event.shiftKey && !(event.nativeEvent?.isComposing === true)) {
            event.preventDefault();
            sendQuestion();
        }
    };

    return (
        <div className={styles.questionInput__container} ref={followUpContextMenuRef}>
            {
                followUpQuestions && followUpQuestions.length > 0
                && <>
                    <div style={{ display: "flex", alignItems: "center", alignSelf: "end", height: 40 }}>
                        <DefaultButton
                            styles={{
                                root: { minWidth: "110px", height: "30px", padding: "0px 5px", borderRadius: "20px", backgroundColor: "rgba(145, 158, 171, 0.24)", border: "none" },
                                flexContainer: { gap: "5px", flexDirection: 'row-reverse' },
                                textContainer: { flexGrow: "0" },
                                label: { fontWeight: 400, fontSize: "12px", lineHeight: "18px", color: "#1c252e", margin: "0px" }
                            }}
                            text={`${followUpQuestions.length} follow-ups`}
                            onRenderIcon={() => { return (<IconFollowUpChevronUp />); }}
                            onClick={toggleIsCalloutVisible}
                        />
                    </div>
                    {
                        isCalloutVisible &&
                        <Callout
                            className={styles["question-input__contextual-menu"]}
                            target={followUpContextMenuRef}
                            isBeakVisible={false}
                            gapSpace={10}
                            onDismiss={toggleIsCalloutVisible}
                            directionalHintFixed={true}
                            directionalHint={DirectionalHint.topLeftEdge}
                            alignTargetEdge={true}
                            styles={{ calloutMain: { padding: "4px", borderRadius: "12px", display: "flex", flexDirection: "column", gap: "4px" } }}
                        >
                            {
                                followUpQuestions.map(question =>
                                    <div
                                        className={styles["question-input__contextual-menu__item"]}
                                        onClick={() => { toggleIsCalloutVisible(); updateUserPrompt(question); }}
                                    >
                                        {question}
                                    </div>
                                )
                            }
                        </Callout>
                    }
                </>
            }
            <div className={styles.questionInput__textAreaContainer}>
                <textarea
                    className={styles.questionInput__textArea}
                    placeholder={placeholder}
                    aria-invalid="false"
                    value={userPrompt}
                    ref={textareaRef}
                    onChange={(e) => updateUserPrompt(e.target.value)}
                    rows={1}
                    onKeyDown={onEnterPress}
                    style={{ resize: 'none', overflow: 'hidden' }}
                    disabled={disabled || !appStateContext?.state.isValidCurrentKnowledgeDomain}
                ></textarea>
            </div>

            <div
                className={styles.questionInput__sendButtonContainer}
                role="button"
                tabIndex={0}
                aria-label="Ask question button"
                onClick={sendQuestion}
                onKeyDown={e => e.key === "Enter" || e.key === " " ? sendQuestion() : null}
            >
                <svg className={styles.questionInput__sendButton} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#1C252E" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.402 14.6631C11.142 12.3291 13.545 10.6151 15.668 11.6211L27.612 17.2791C29.9 18.3621 29.9 21.6181 27.612 22.7011L15.668 28.3601C13.545 29.3661 11.143 27.6521 11.402 25.3181L11.882 20.9901H20C20.2652 20.9901 20.5195 20.8848 20.7071 20.6972C20.8946 20.5097 21 20.2553 21 19.9901C21 19.7249 20.8946 19.4705 20.7071 19.283C20.5195 19.0955 20.2652 18.9901 20 18.9901H11.883L11.403 14.6631H11.402Z" fill="white" />
                </svg>
            </div>
        </div>
    );
};

export default QuestionInput;