export const MenuItemIcon = () => {
  return <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_4215_201002)">
      <path d="M2 21C2 9.95431 10.9543 1 22 1C33.0457 1 42 9.95431 42 21C42 32.0457 33.0457 41 22 41C10.9543 41 2 32.0457 2 21Z" fill="white" shape-rendering="crispEdges" />
      <path d="M2.5 21C2.5 10.2304 11.2304 1.5 22 1.5C32.7696 1.5 41.5 10.2304 41.5 21C41.5 31.7696 32.7696 40.5 22 40.5C11.2304 40.5 2.5 31.7696 2.5 21Z" stroke="#919EAB" stroke-opacity="0.32" shape-rendering="crispEdges" />
      <path opacity="0.32" d="M25.8768 13.3076H15.0464C14.0736 13.3076 13.2821 13.8826 13.2821 14.5901C13.2821 15.2968 14.0736 15.8717 15.0464 15.8717H25.8768C26.8496 15.8717 27.6411 15.2968 27.6411 14.5901C27.6411 13.8825 26.8496 13.3076 25.8768 13.3076Z" fill="#637381" />
      <path d="M28.9537 19.7183H18.1233C17.1505 19.7183 16.359 20.2932 16.359 21.0007C16.359 21.7074 17.1505 22.2824 18.1233 22.2824H28.9537C29.9265 22.2824 30.718 21.7074 30.718 21.0007C30.718 20.2932 29.9266 19.7183 28.9537 19.7183Z" fill="#919EAB" />
      <path d="M25.8768 26.1285H15.0464C14.0736 26.1285 13.2821 26.7035 13.2821 27.411C13.2821 28.1177 14.0736 28.6926 15.0464 28.6926H25.8768C26.8496 28.6926 27.6411 28.1177 27.6411 27.411C27.6411 26.7035 26.8496 26.1285 25.8768 26.1285Z" fill="#919EAB" />
    </g>
    <defs>
      <filter id="filter0_d_4215_201002" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.568627 0 0 0 0 0.619608 0 0 0 0 0.670588 0 0 0 0.16 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4215_201002" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4215_201002" result="shape" />
      </filter>
    </defs>
  </svg>
}