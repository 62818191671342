const IconTooltipInfo: React.FunctionComponent = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4564_4580)">
                <path d="M9 16C10.3845 16 11.7378 15.5895 12.889 14.8203C14.0401 14.0511 14.9373 12.9579 15.4672 11.6788C15.997 10.3997 16.1356 8.99224 15.8655 7.63437C15.5954 6.2765 14.9287 5.02922 13.9497 4.05026C12.9708 3.07129 11.7235 2.4046 10.3656 2.13451C9.00777 1.86441 7.6003 2.00303 6.32122 2.53285C5.04213 3.06266 3.94888 3.95987 3.17971 5.11101C2.41054 6.26216 2 7.61553 2 9C2.00201 10.8559 2.74015 12.6352 4.05247 13.9475C5.36479 15.2599 7.1441 15.998 9 16ZM9 4.91667C9.17306 4.91667 9.34223 4.96799 9.48612 5.06413C9.63002 5.16028 9.74217 5.29694 9.8084 5.45682C9.87462 5.61671 9.89195 5.79264 9.85819 5.96237C9.82443 6.13211 9.74109 6.28802 9.61872 6.41039C9.49635 6.53276 9.34044 6.61609 9.1707 6.64986C9.00097 6.68362 8.82504 6.66629 8.66515 6.60006C8.50527 6.53384 8.36861 6.42169 8.27246 6.27779C8.17632 6.1339 8.125 5.96473 8.125 5.79167C8.125 5.55961 8.21719 5.33705 8.38128 5.17295C8.54538 5.00886 8.76794 4.91667 9 4.91667ZM8.41667 7.83334H9C9.30942 7.83334 9.60617 7.95625 9.82496 8.17505C10.0438 8.39384 10.1667 8.69058 10.1667 9V12.5C10.1667 12.6547 10.1052 12.8031 9.99581 12.9125C9.88642 13.0219 9.73804 13.0833 9.58333 13.0833C9.42862 13.0833 9.28025 13.0219 9.17086 12.9125C9.06146 12.8031 9 12.6547 9 12.5V9H8.41667C8.26196 9 8.11358 8.93854 8.00419 8.82915C7.89479 8.71975 7.83333 8.57138 7.83333 8.41667C7.83333 8.26196 7.89479 8.11359 8.00419 8.00419C8.11358 7.89479 8.26196 7.83334 8.41667 7.83334Z" fill="#8E95A6" />
            </g>
            <defs>
                <clipPath id="clip0_4564_4580">
                    <rect width="14" height="14" fill="white" transform="translate(2 2)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconTooltipInfo;
