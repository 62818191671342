const IconKnowledgeDomainDefault: React.FunctionComponent = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.32" fill-rule="evenodd" clip-rule="evenodd" d="M4.12319 2.24369C5.3177 2.12083 7.30475 2 10.5 2C13.6952 2 15.6823 2.12083 16.8768 2.24369C17.8972 2.34863 18.6398 3.10549 18.7572 4.12444C18.8797 5.18775 19 6.7933 19 9C19 11.2067 18.8797 12.8123 18.7572 13.8756C18.6398 14.8945 17.8973 15.6514 16.877 15.7563C15.822 15.8648 14.1489 15.9717 11.571 15.9952C11.1714 15.9989 10.7875 16.1592 10.507 16.4437L8.816 18.1584C8.08185 18.9029 6.8125 18.4707 6.6853 17.4328L6.55145 16.3414C6.52175 16.099 6.3197 15.9137 6.0759 15.9011C5.26545 15.859 4.62502 15.8079 4.12397 15.7564C3.10365 15.6515 2.36022 14.8945 2.24278 13.8756C2.12023 12.8123 2 11.2067 2 9C2 6.7933 2.12023 5.18775 2.24278 4.12444C2.36022 3.10549 3.10287 2.34863 4.12319 2.24369ZM7 6C6.4477 6 6 6.4477 6 7C6 7.5523 6.4477 8 7 8H14C14.5523 8 15 7.5523 15 7C15 6.4477 14.5523 6 14 6H7ZM7 10.5C6.4477 10.5 6 10.9477 6 11.5C6 12.0523 6.4477 12.5 7 12.5H11C11.5523 12.5 12 12.0523 12 11.5C12 10.9477 11.5523 10.5 11 10.5H7Z" fill="#006C9C" />
            <path d="M5.99927 7C5.99927 6.4477 6.44697 6 6.99927 6H13.9993C14.5516 6 14.9993 6.4477 14.9993 7C14.9993 7.5523 14.5516 8 13.9993 8H6.99927C6.44697 8 5.99927 7.5523 5.99927 7Z" fill="#006C9C" />
            <path d="M5.99927 11.5C5.99927 10.9477 6.44697 10.5 6.99927 10.5H10.9993C11.5516 10.5 11.9993 10.9477 11.9993 11.5C11.9993 12.0523 11.5516 12.5 10.9993 12.5H6.99927C6.44697 12.5 5.99927 12.0523 5.99927 11.5Z" fill="#006C9C" />
            <path d="M10.0259 16.9308L10.5063 16.4437C10.7868 16.1592 11.1707 15.9989 11.5703 15.9952C14.1481 15.9717 15.8213 15.8648 16.8763 15.7563C17.8966 15.6514 18.6391 14.8945 18.7565 13.8755C18.8365 13.1809 18.9156 12.2547 18.9608 11.0808C19.4676 11.1109 19.8667 11.1462 20.1775 11.1811C20.803 11.2515 21.2617 11.7033 21.3395 12.3279C21.4208 12.9808 21.4993 13.9995 21.4993 15.5C21.4993 17.0005 21.4208 18.0192 21.3395 18.6721C21.2617 19.2967 20.8024 19.7486 20.1769 19.8189C19.7588 19.8659 19.1811 19.9136 18.3964 19.9483C18.1644 19.9585 17.9675 20.1252 17.9226 20.3531L17.7519 21.219C17.6399 21.7868 16.9541 22.0192 16.52 21.6364L15.0714 20.3589C14.8031 20.1223 14.4578 19.9901 14.1001 19.9816C12.7852 19.9504 11.9012 19.884 11.3225 19.819C10.697 19.7488 10.2369 19.2967 10.1591 18.6721C10.1068 18.2528 10.0558 17.6826 10.0259 16.9308Z" fill="#006C9C" />
        </svg>
    );
};

export default IconKnowledgeDomainDefault;